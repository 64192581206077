@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-image: url('./assets/images/back.jpg');
  font-family: 'Poppins', sans-serif;
  width: 100%;
  height: auto;
  overflow-x: hidden;
}

.cookie {
  position: absolute;
  top: 7vh;
  right: -5%;
  border-radius: 8px;
  width: 240px;
  height: 40px;
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 12px;
  padding: 7px 20px 5px 50px;
  -webkit-animation: slide 1.5s forwards;
  animation: slide 1.5s forwards;
  z-index: 10;
}

@-webkit-keyframes slide {
  100% {
    right: 6%;
  }
}

@keyframes slide {
  100% {
    right: 6%;
  }
}

.cookie .iconCookie {
  position: absolute;
  top: 10px;
  left: 10px;
  display: block;
  font-size: 24px;
  color: #fdd314;
}

.cookie .cross {
  position: absolute;
  top: 5px;
  right: 5px;
  display: block;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
}

.welcome {
  display: inline-block;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.welcome .back {
  position: absolute;
  top: -30px;
  height: 30px;
  right: 30px;
  width: 140px;
  font-size: 12px;
  color: #fff;
  background: linear-gradient(45deg, #ed4a20 0%, #c22c23 100%);
  cursor: pointer;
  text-align: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-weight: bold;
  line-height: 30px;
}

.welcome .back:hover {
  background: #c22c23;
}

.welcome .logo {
  font-size: 38px;
  text-align: center;
  color: #fafafa;
  font-weight: bold;
  margin: 60px auto 20px auto;
  letter-spacing: 2px;
  cursor: pointer;
}

.welcome .imglogo {
  display: block;
  margin: 0 auto;
  width: 180px;
}

.welcome .logo span {
  color: #fff;
  font-weight: bolder;
}

.welcome .logo .tagline {
  font-size: 14px;
  display: block;
  text-align: center;
  margin: 0 auto;
  color: #fff;
}

.welcome .access {
  font-size: 48px;
  display: block;
  text-align: left;
  margin: 30px 40px;
  color: #fff;
}

.welcome .access span {
  font-size: 28px;
  display: block;
}

.welcome .board {
  backdrop-filter: blur(8px);
  width: 90%;
  margin: 5vh auto;
  border-radius: 14px;
  min-height: 90vh;
  color: #fff;
  display: block;
  position: relative;
  background-color: rgba(0, 0, 0, 0.4);
}

.welcome .board .bmenu {
  display: none;
}

.welcome .board .selecter {
  display: inline-block;
  padding: 0 20px 0 20px;
  width: 220px;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  min-height: 90vh;
  vertical-align: top;
  background-color: rgba(0, 0, 0, 0.1);
}

.welcome .board .selecter .closer {
  display: none;
}

.welcome .board .avatarholder {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 0 4px;
  border-radius: 30px;
  border: 2px solid #dedee0;
}

.welcome .board .username {
  display: inline-block;
  margin: 0 4px;
  font-size: 13px;
  font-weight: bold;
  height: 40px;
  line-height: 26px;
  vertical-align: top;
}

.welcome .board .action {
  display: inline-block;
  padding: 0 20px 0 20px;
  width: calc(100% - 300px);
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  height: 90vh;
  vertical-align: top;
  overflow-x: hidden;
}

.welcome .board .cover {
  background: linear-gradient(45deg, #682b9a 0%, #411761 100%);
  color: #fff;
  display: inline-block;
}

.welcome .board .cover h3 {
  color: #fff !important;
}

.welcome .board .abstract {
  position: absolute;
  left: 185px;
  top: -8px;
  height: 690px;
  display: block;
  width: 150px;
}

.welcome .board .abstractline01 {
  position: absolute;
  background: linear-gradient(100deg, #7422b3 0%, #4a166f 100%);
  left: 250px;
  top: 0;
  height: 690px;
  width: 10px;
  display: block;
  box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.7);
}

.welcome .board .abstractline02 {
  position: absolute;
  background: linear-gradient(100deg, #1174c9 0%, #004a89 100%);
  left: 260px;
  top: 0;
  height: 690px;
  width: 10px;
  display: block;
  box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.7);
}

.welcome .board .abstractline03 {
  position: absolute;
  background: linear-gradient(100deg, #f47200 0%, #bd5300 100%);
  left: 270px;
  top: 0;
  height: 690px;
  width: 14px;
  display: block;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  clip-path: inset(0px -15px 0px 0px);
}

.welcome .board h3 {
  color: #fff;
  text-align: left;
  display: block;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 0;
}

.welcome .board .custom {
  font-size: 12px;
  position: relative;
  padding: 2px 8px;
  padding-left: 28px;
  background: linear-gradient(45deg, #8d8d8d 0%, #3b3b3b 100%);
  text-align: center;
  border-radius: 4px;
  color: #fff;
  margin-left: 4px;
  cursor: pointer;
}

.welcome .board .custom svg {
  left: 6px;
  top: 4px;
  position: absolute;
}

.welcome .board .custom:hover {
  background: linear-gradient(45deg, #037fe4 0%, #024074 100%);
}

.welcome .board img {
  display: block;
  margin: 8px auto;
}

.welcome .board p {
  margin: 0;
  text-align: justify;
  font-size: 14px;
}

.welcome .board .seperator {
  width: 100%;
  height: 2px;
  margin-top: 10px;
}

.welcome .board h4 {
  color: #dd700a;
  padding-bottom: 10px;
  text-align: center;
  display: block;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 10px;
}

.welcome .board a {
  color: #ed4a20;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none !important;
}

.welcome .board a:hover {
  color: #c22c23;
}

.welcome button {
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  color: #fff;
  border-radius: 8px;
  display: block;
  margin: 10px 0 10px 0;
  cursor: pointer;
  border: 0;
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  padding-left: 30px;
  font-weight: bold;
  text-align: left;
  width: 180px;
  position: relative;
}

.welcome button:hover {
  background-color: #ed4a20;
  color: #fff;
}

.welcome .wenabler:hover {
  background-color: #0057a0 !important;
  color: #fff;
}

.welcome .active {
  background-color: #ed4a20 !important;
  color: #fff;
}

.welcome .wactive {
  background-color: #0057a0 !important;
  color: #fff;
}

.welcome button svg {
  position: absolute;
  left: 10px;
  top: 11px;
}

.welcome input[type=text],
input[type=email],
input[type=password],
input[type=number], select {
  width: 220px;
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
  display: block;
  margin: 16px auto;
  font-size: 14px;
  padding: 0 20px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  border: 0;
  border-bottom: 2px solid #043cd6;
}

.welcome .amount {
  width: 120px;
  height: 40px;
  line-height: 40px;
  border-bottom: 2px solid #043cd6;
  border-radius: 8px;
  display: inline-block;
  margin: 0 2px;
  font-size: 14px;
  padding: 0 20px;
}

.welcome select {
  width: 160px;
  height: 30px;
  line-height: 30px;
  border-radius: 8px;
  display: block;
  margin: 8px 0 20px 0;
  font-size: 14px;
  padding: 0 10px;
}

.welcome .cur {
  width: 90px;
  height: 40px;
  line-height: 40px;
  border-bottom: 2px solid #043cd6;;
  border-radius: 8px;
  display: inline-block;
  margin: 0 2px;
  font-size: 14px;
  padding: 0 10px;
}

.welcome textarea {
  width: 280px;
  height: 94px;
  border: 1px solid #037fe4;
  border-radius: 8px;
  display: block;
  margin: 16px auto;
  font-size: 14px;
  padding: 10px 20px;
  color: #fff;
  font-weight: bold;
  resize: none;
  background-color: rgba(0, 0, 0, 0.4);
}

.welcome .holder {
  width: fit-content;
  display: block;
  margin: 20px;
}

.welcome .parameters {
  width: 260px;
  display: block;
  margin: 20px auto 0 auto;
}

.welcome #card {
  display: block;
  border-radius: 14px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  width: 453px;
  height: 200px;
  position: relative;
  background: #ed4a23;
  background: linear-gradient(46deg, #18b6ff 0%, #000dff 100%);
  margin-bottom: 10px;
}

.welcome #card .full {
  color: #eee;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  text-align: center !important;
  margin: 10px auto 20px auto;
  font-weight: bold;
  display: block;
}

.welcome .actionbox {
  background: rgba(0, 0, 0, 0.4);
  height: 42px;
  width: 360px;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 8px;
  display: inline-block;
  vertical-align: top;
}

.welcome .simplebox {
  background: rgba(0, 0, 0, 0.6);
  height: 42px;
  width: 118px;
}

.welcome .actionbox .entry {
  height: 38px;
  width: 118px;
  text-align: center;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  vertical-align: top;
  padding-top: 5px;
  cursor: pointer;
}

.welcome .actionbox .entry small {
  display: block;
  margin-top: -4px;
}

.welcome .actionbox .entry:hover {
  color: #fa7b03;
}

.welcome .actionbox .line {
  height: 40px;
  width: 1px;
  background: #000;
  display: inline-block;
}

.welcome .downloader {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 45px;
  color: #fff;
  background: linear-gradient(45deg, #8d8d8d 0%, #383838 100%);
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  margin: 5px 5px 5px 0;
}

.welcome .downloader:hover {
  background: #ed4a20;
}

.welcome .social {
  width: 50px;
  height: 200px;
  position: absolute;
  top: 80px;
  right: 40px;
  cursor: pointer;
}

.welcome .share {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 45px;
  color: #fff;
  background: linear-gradient(45deg, #8d8d8d 0%, #383838 100%);
  border-radius: 8px;
  position: absolute;
  top: 150px;
  right: 40px;
  cursor: pointer;
}

.welcome .share:hover {
  background: #ed4a20;
}

.welcome .remove {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 45px;
  color: #838383;
  background: linear-gradient(45deg, #d3d2d2 0%, #acabab 100%);
  border-radius: 8px;
  display: inline-block;
  vertical-align: top;
  margin: 5px 5px 5px 0;
  cursor: pointer;
}

.welcome .remove:hover {
  background: #b3b3b3;
}

.welcome .beacon {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 45px;
  color: #fff;
  background: linear-gradient(45deg, #3788f3 0%, #0661d8 100%);
  border-radius: 8px;
  display: inline-block;
  vertical-align: top;
  margin: 5px 5px 5px 0;
  cursor: pointer;
}

.welcome .close {
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 40px;
  color: #838383;
  background: linear-gradient(45deg, #d3d2d2 0%, #acabab 100%);
  cursor: pointer;
  display: block;
  margin: 20px 10px;
}

.welcome .close:hover {
  background: #b3b3b3;
}

.welcome .avatarbox {
  display: inline-block;
  width: 40%;
}

.welcome .minavatar {
  width: 55px;
  height: 55px;
  margin: 8px !important;
  border-radius: 42px;
  border: 2px solid #fff;
}

.welcome .balance {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  width: 60%;
  align-content: center;
  vertical-align: top;
  color: #fff;
  position: relative;
  height: 140px;
}

.welcome .balance .nb {
  display: block;
  font-size: 20px;
}

.welcome .balance .currency {
  font-size: 16px;
  margin-left: 10px;
  color: #eee;
}

.welcome .balance .network {
  display: block;
  font-size: 11px;
  color: #eee;
}

.welcome #card .tezorus {
  width: 90px;
  right: 20px;
  top: 10px;
  position: absolute;
}

.welcome canvas {
  display: block;
  margin: 10px auto 10px auto;
}

.welcome .gauge {
  width: 220px;
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  background-color: #0661d8;
  color: #fff;
  padding: 0 20px;
  margin: 0 auto;
  font-size: 11px;
  margin-bottom: 20px;
}

.welcome .policy {
  line-height: 30px;
  vertical-align: top;
  margin-left: 10px;
  font-size: 12px;
}

.welcome .avatargen {
  display: block;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 42px;
  border: 2px solid #dedee0;
}

.welcome .icon {
  display: block;
  width: 80px;
  height: 80px;
  margin: 10px auto;
}

.welcome .dataprivacy {
  font-size: 10px !important;
  margin: 5px auto !important;
  max-width: 500px;
  display: block;
}

.welcome .submit {
  background: linear-gradient(45deg, #ed4a20 0%, #c22c23 100%);
  cursor: pointer;
  color: #fff;
  border-radius: 8px;
  display: block;
  margin: 10px auto;
  cursor: pointer;
  border: 0;
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  width: 160px;
  position: relative;
}

.welcome .submit:hover {
  background: #c22c23;
  color: #fff;
}

.welcome .cancel {
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  display: block;
  margin: 20px auto;
  background-color: rgba(0, 0, 0, 0.5);
  height: 30px;
  line-height: 30px;
  border-radius: 8px;
  width: 160px;
  text-align: center;
  color: #eee;
}

.welcome .cancel:hover {
  background-color: #838383;
  font-weight: bold;
}

.welcome .dashboard {
  background: linear-gradient(45deg, #037fe4 0%, #024074 100%);
}

.welcome .dashboard:hover {
  background: #024074 !important;
  color: #fff;
}

.welcome .account {
  width: 100%;
  padding-left: 40px;
}

.welcome .card {
  max-width: 300px;
  padding: 20px;
  border-radius: 14px;
  display: inline-block;
  margin: 20px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  background-color: rgba(0, 0, 0, 0.4);
  vertical-align: top;
  transition: all .2s ease-in-out;
}

.welcome .card:hover {
  transform: scale(1.04);
  background-color: rgba(0, 0, 0, 0.7);
}

.welcome .card h2 {
  margin-top: 0;
}

.welcome .cardAction {
  width: fit-content;
  padding: 20px;
  border-radius: 14px;
  display: block;
  margin: 20px auto;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
}

.welcome .bblue {
  background: linear-gradient(100deg, #1174c9 0%, #004a89 100%);
  color: #fff !important;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  border: 0;
}

.welcome .card img {
  height: 65px;
  display: block;
  margin: 5px auto 20px auto;
}

.welcome .tokens {
  width: 100%;
  padding-left: 40px;
}

.welcome .tokens .token {
  display: inline-block;
  vertical-align: top;
  width: fit-content;
  margin: 10px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  border-bottom: 2px solid #0661d8;
}

.popnft {
  min-width: calc(100% - 50px);
  height: 85vh;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(6px);
  border-radius: 14px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  overflow-y: auto;
  padding: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  border: 4px solid #0661d8 !important;
}

.welcome .tokens .token .thumbnail {
  display: inline-block;
  width: 70px;
  vertical-align: top;
}

.welcome .tokens .token .thumbnail img {
  display: block;
  margin: 0 auto;
  width: 50px;
}

.welcome .tokens .token .nft {
  display: block;
  margin: 0 auto 10px auto;
  width: 305px;
  height: 300px;
  border-radius: 4px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  background-size: cover;
  background-position: center center;
  cursor: pointer;
}

.welcome .tokens .popnft .visual {
  height: 50vh;
  display: block;
  margin: 20px auto;
}

.welcome .tokens .token .identity {
  display: inline-block;
  vertical-align: top;
  max-width: 300px;
  margin-left: 10px;
}

.welcome .tokens .popnft .identity {
  max-width: 100%;
  width: calc(100% - 40px);
  margin: 0;
  padding: 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: center !important;
}

.welcome .tokens .token .identity .thumbnail{
  display: inline-block;
  vertical-align: top;
  width: 50px;
}

.welcome .tokens .token .identity img{
  display: block;
  margin: 2px 10px 2px 0;
  width: 40px;
  height: 40px;
}

.welcome .tokens .token .identity .details{
  display: inline-block;
  vertical-align: top;
  max-width: 200px;
}

.welcome .tokens .popnft .closing {
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
  font-size: 22px;
  color: #fff;
}

.welcome .tokens .token .identity .name {
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.welcome .tokens .token .identity .desc {
  font-size: 12px;
  display: block;
}

.welcome .tokens .token .identity .send {
  font-size: 12px;
  position: relative;
  padding: 2px 6px;
  padding-left: 18px;
  background: linear-gradient(45deg, #ed4a20 0%, #c22c23 100%);
  text-align: center;
  border-radius: 4px;
  color: #fff;
  margin-left: 4px;
  cursor: pointer;
}

.welcome .tokens .token .identity .send svg {
  left: 4px;
  top: 4px;
  position: absolute;
}

.welcome .tokens .token .identity .send:hover {
  background: #c22c23;
}

.welcome .tokens .token .empty {
  font-size: 14px;
  display: inline-block;
  background-color: rgb(230, 227, 227);
  color: #575555;
  text-align: center;
  border-radius: 8px;
  width: 110px;
  height: 50px;
  padding-top: 10px;
  line-height: 20px;
}

.welcome .tokens .token .filled {
  font-size: 16px;
  display: inline-block;
  background: linear-gradient(45deg, #037fe4 0%, #024074 100%);
  color: #fff;
  text-align: center;
  border-radius: 8px;
  width: 110px;
  height: 50px;
  padding-top: 10px;
  line-height: 20px;
}

.welcome .tokens .token .identity .badge {
  background: linear-gradient(45deg, #037fe4 0%, #024074 100%);
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 8px;
  color: #fff !important;
  text-align: center;
  display: block;
  width: 100px;
  margin: 5px 0;
}

.welcome .tokens .token .popnft .badge {
  margin: 5px auto !important;
}

.tcenter {
  text-align: center !important;
}

.terror {
  color: #ff0000 !important;
}

.tsuccess {
  color: rgb(2, 139, 59);
}

.tinfo {
  color: #037fe4;
}

.twhite {
  color: #fff !important;
}

.badge-loading {
  background: linear-gradient(45deg, #037fe4 0%, #024074 100%);
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 8px;
  color: #fff !important;
  text-align: center;
  display: block;
  width: 100px;
  margin: 4px auto;
}

.extend {
  height: 90vh !important;
}

.overflow {
  overflow-y: auto !important;
}

.pointer {
  cursor: pointer !important;
}

.maxi {
  font-size: 48px;
  display: block;
  margin: 200px auto;
  color: #dd700a;
}

.validated {
  font-size: 48px;
  display: block;
  margin: 200px auto;
  color: #0ac457;
}

.legal {
  position: absolute;
  width: 240px;
  bottom: 20px;
  left: 20px;
  text-align: center;
  color: #bbb !important;
}

.lmargin {
  margin-left: 4px;
}

.lsafe {
  margin-left: 40px !important;
}

.tmargin {
  margin-top: 20px !important;
}

.bmargin {
  margin-bottom: 20px !important;
}

.tnull {
  margin-top: 0 !important;
}

.bnull {
  margin-bottom: 0 !important;
}

.iblock {
  display: inline-block;
}

.safeleft {
  padding: 80px;
}

.dnone {
  display: none !important;
}

.spinner {
  animation: spin infinite 2s linear;
}

.blur {
  width: 100%;
  height: 90vh;
  backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 16px;
}

.confirm {
  width: 300px;
  height: 430px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(6px);
  border-radius: 14px;
  position: fixed;
  top: calc(45vh - 215px);
  left: calc(45vw - 150px);
  z-index: 5;
  overflow-y: auto;
  padding: 0;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  border: 4px solid #ed4a20;
}

.publish {
  height: 220px !important;
  top: calc(50vh - 110px) !important;
}

.connectbeacon {
  height: auto !important;
  max-height: calc(100vh - 160px);
  top: calc(80px) !important;
  overflow-y: auto;
}

.confirm .entry {
  display: block;
  margin: 0;
  border-bottom: 1px solid #333;
  padding: 4px 14px;
  cursor: pointer;
}

.confirm .entry:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.confirm .block {
  cursor: default !important;
  border-bottom: 0 !important;
}

.confirm .entry .avatar {
  display: inline-block;
  width: 70px;
  align-items: center;
}

.confirm .entry .avatar img {
  display: block;
  width: 60px;
  margin: 0 auto;
}

.confirm .entry .content {
  display: inline-block;
  width: 180px;
  padding-left: 10px;
  overflow-x: auto;
}

.dblock {
  display: block !important;
  margin: 10px auto !important;
}

.bradius {
  border-radius: 4px !important;
}

rect {
  border-radius: 4px !important;
}

.poweredby {
  position: fixed;
  bottom: calc(5vh + 30px);
  right: calc(5% + 40px);
  width: 180px;
  cursor: pointer;
  z-index: 2;
}

.mleft {
  margin-left: 6px;
}

.mtop {
  margin-top: 6px;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  margin: 1em 0;
  padding: 0;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.myaccount{
  padding: 20px;
  border-radius: 8px;
  background: rgb(0, 161, 161);
  background: linear-gradient(106deg, rgba(0, 161, 161, 0.5) 0%, rgba(157, 0, 231, 0.5) 100%);
  display: block;
  margin: 0 auto;
  position: absolute;
  bottom: 60px;
  left: 20px;
}

.myprofile{
  padding: 20px;
  border-radius: 8px;
  background: rgb(0, 161, 161);
  background: linear-gradient(106deg, rgba(0, 161, 161, 0.5) 0%, rgba(157, 0, 231, 0.5) 100%);
  display: block;
  margin: 20px auto;
}

@media screen and (max-width: 850px) {
  .welcome .access {
    font-size: 24px;
    margin-top: 20px;
  }

  .welcome .access span {
    font-size: 18px;
  }

  .welcome .board {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    height: 100vh;
    margin-bottom: 0;
    border-radius: 0;
    position: relative;
  }

  .extend {
    height: 100vh !important;
    padding-top: 0;
    padding-bottom: 0;
  }

  .welcome .board h3 {
    text-align: left;
    display: block;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 0;
  }

  .welcome .board .selecter {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 20px;
    width: 180px;
    height: 100vh;
    border-radius: 0;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    border-top-right-radius: 0px;
    box-shadow: -1px 0 2px rgba(0, 0, 0, 0.4);
    background: rgb(0, 161, 161);
    background: linear-gradient(106deg, rgba(0, 161, 161, 0.7) 0%, rgba(157, 0, 231, 0.7) 100%);
    backdrop-filter: blur(6px);
    z-index: 8;
  }

  .welcome .board .selecter .closer {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    width: 30px;
    text-align: right;
    display: block;
    color: #fff;
  }

  .welcome .board .selecter .closer:hover {
    color: #7a7a7a; 
  }

  .welcome .board .bmenu {
    color: #fff;
    display: block;
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
    width: 30px;
    font-size: 28px;
  }

  .welcome .board .bmenu:hover {
    color: #ed4a23;
  }

  .welcome .board .action {
    display: block;
    padding: 10px 0;
    width: 100%;
    border-radius: 0;
    height: calc(100vh - 28px);
    border-radius: 0;
  }

  .welcome .card {
    width: 100%;
    display: block;
    margin: 20px auto;
  }

  .welcome .cardAction {
    width: calc(100% - 80px);
  }

  .welcome .board .abstract {
    position: absolute;
    left: -130px;
    top: -8px;
    height: calc(100vh - 198px);
    display: block;
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .welcome .board .abstract2 {
    position: absolute;
    left: -130px;
    top: -8px;
    height: calc(100vh - 198px);
    display: block;
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .welcome .dataprivacy {
    margin: 5px auto !important;
    max-width: 100%;
  }

  .welcome .account {
    padding-left: 0;
  }

  .welcome .holder {
    width: 100% !important;
    display: block;
    margin: 20px 0;
    padding: 0;
  }

  .welcome #card {
    width: calc(100vw - 80px);
    display: block;
    margin: 0 auto;
  }

  .welcome .downloader {
    margin: 5px 5px 5px 5%;
  }

  .welcome .share {
    margin: 5px;
  }

  .welcome .remove {
    margin: 5px 5px 5px 5%;
  }

  .welcome .beacon {
    margin: 5px;
  }

  .welcome .actionbox {
    width: 90%;
    text-align: center;
    margin-left: 5%;
  }

  .welcome .actionbox .entry {
    width: 32%;
    align-items: center !important;
  }

  .welcome .tokens {
    width: calc(100% - 20px);
    padding-left: 0;
    display: block;
    margin: 10px auto;
  }

  .welcome .tokens .token {
    width: 90%;
    display: block;
    margin: 10px auto;
  }

  .welcome .tokens .token .nft {
    width: 100%;
  }

  .welcome .tokens .token .thumbnail {
    width: 50px;
  }

  .welcome .tokens .token .thumbnail img {
    margin: 0 auto;
    width: 38px;
  }

  .welcome .tokens .token .identity {
    width: calc(100vw - 180px);
  }

  .welcome .tokens .token .identity .name {
    font-size: 14px;
  }

  .welcome .tokens .token .identity .desc {
    font-size: 11px;
  }

  .welcome .tokens .token .identity .send {
    font-size: 11px;
  }

  .welcome .tokens .token .empty {
    font-size: 12px;
    width: 80px;
  }

  .welcome .tokens .token .filled {
    font-size: 12px;
    width: 80px;
  }

  .lsafe {
    margin-left: 0;
  }

  .poweredby {
    width: 140px;
    bottom: 10px;
    right: auto;
    left: 20px;
  }

  .myaccount .avatarholder {
    display: none !important;
  }

  .myaccount button {
    width: 140px !important;
  }
}